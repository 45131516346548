import { DELIVERY_CLASSES } from "@today/api/common"
import { Client, ListClientsResponse } from "@today/api/taker"
import {
  Load,
  LoadFilterType,
  Organization,
  OrgId,
  PolicyType,
  RegionSet,
  Station,
} from "@today/api/tracker"
import {
  getDeliveryClassName,
  getLoadStateName,
  getStationsOfOrg,
  partition,
  usePagination,
} from "@today/lib"
import { Button } from "baseui/button"
import { StatefulPopover } from "baseui/popover"
import { Value } from "baseui/select"
import { useEffect, useMemo, useState } from "react"
import useSWR from "swr"
import { FilterItem } from "../FilterItem"

type LoadFilterProps = {
  onUpdateFilters: (filters: LoadFilterType[]) => void
  updatedFilters?: Record<string, Value>
  orgId?: OrgId
}

const LOAD_STATES: Load["state"][] = [
  "PENDING_COLLECT",
  "HOLDING_COLLECT",
  "IN_COLLECT",
  "IN_TRANSPORT",
  "IN_DELIVERY",
  "HOLDING_DELIVERY",
  "AT_STATION",
  "DELIVERED",
  "QUIT",
]

const smeRounds = [
  {
    id: "11:00:00",
    name: "12시",
  },
  {
    id: "14:00:00",
    name: "15시",
  },
  {
    id: "18:00:00",
    name: "19시",
  },
]

const policyTypes = [
  { id: "TODAY_EVENING", label: "LMTE" },
  { id: "TODAY_MORNING", label: "LMTM" },
  { id: "SME", label: "LMSME" },
]

const PAGE_SIZE = 100

export function LoadFilter({
  onUpdateFilters,
  updatedFilters,
  orgId,
}: LoadFilterProps) {
  const { data: clientsData } = usePagination<ListClientsResponse>(
    `/api/clients`,
    PAGE_SIZE,
    true
  )
  const { data: regionSetsData } = useSWR<RegionSet[]>(
    orgId ? `/api/orgs/${orgId}/region-sets` : `/api/region-sets`
  )
  const { data: stationsData } = useSWR<Station[]>(`/api/stations`)
  const { data: organizationsData } = useSWR<Organization[]>(
    `/api/organizations?filter=active=true`
  )
  const organizations = useMemo(
    () => organizationsData?.filter(({ type }) => type === "FORWARDING"),
    [organizationsData]
  )
  const [selectedStates, setSelectedStates] = useState<Value>([])
  const [selectedClients, setSelectedClients] = useState<Value>([])
  const [selectedStations, setSelectedStations] = useState<Value>([])
  const [selectedPolicyTypes, setSelectedPolicyTypes] = useState<Value>([
    policyTypes[0],
  ])
  const [selectedRegionSets, setSelectedRegionSets] = useState<Value>([])
  const selectedRegionSetsOfPolicyType = useMemo(() => {
    return selectedRegionSets.filter(
      ({ id }) =>
        id?.toString().endsWith(selectedPolicyTypes[0].label as string) ||
        id?.toString().endsWith("*")
    )
  }, [selectedRegionSets, selectedPolicyTypes])
  const [selectedLastMileSiDos, setSelectedLastMileSiDos] = useState<Value>([])
  const [selectedDeliveryClasses, setSelectedDeliveryClasses] = useState<Value>(
    []
  )
  const [selectedShippingTypes, setSelectedShippingTypes] = useState<Value>([])
  const [selectedOrganizations, setSelectedOrganizations] = useState<Value>([])
  const hasSelectedReturning =
    selectedDeliveryClasses.length === 1 &&
    selectedDeliveryClasses[0].id === "RETURNING"
  const hasSelectedFromLM =
    selectedShippingTypes.length &&
    selectedShippingTypes.every((e) => `${e.id}`.startsWith("LM_TO_"))
  const [selectedIsReady, setSelectedIsReady] = useState<Value>([])
  const [selectedRound, setSelectedRound] = useState<Value>([])
  const [filters, setFilters] = useState<LoadFilterType[]>([])
  const clientOptions = useMemo(
    () =>
      clientsData
        ? getClientOptions([...clientsData.flatMap((res) => res.clients)])
        : undefined,
    [clientsData]
  )
  const regionSets = useMemo(() => {
    if (regionSetsData) {
      return [...regionSetsData]
        .filter(
          (regionSet) => regionSet.regionSetPolicyType !== "TODAY_OVERNIGHT"
        )
        .sort((a, b) => {
          function getPriority(regionSet: RegionSet) {
            if (regionSet.regionSetPolicyType !== "TODAY_EVENING") {
              return 1
            }
            return 0
          }
          const priorityA = getPriority(a)
          const priorityB = getPriority(b)
          return priorityA === priorityB
            ? a.regionSetName.localeCompare(b.regionSetName)
            : priorityA - priorityB
        })
    }

    return undefined
  }, [regionSetsData])

  const stations = useMemo(() => {
    if (stationsData) {
      const stations = orgId
        ? getStationsOfOrg(orgId, [...stationsData])
        : [...stationsData]

      return getStationsForFilter(stations)
    }

    return undefined
  }, [orgId, stationsData])

  const targetRegionSets = useMemo(
    () => [
      ...(stations ?? []).map<RegionSet>(({ name: stationName }) => ({
        regionSetName: `${stationName}-*`,
        regionSetPolicyType: selectedPolicyTypes[0].id as PolicyType,
        regionSetCode: "",
        regionIds: [],
      })),
      ...(regionSets ?? []).filter((regionSets) =>
        regionSets.regionSetName.endsWith(
          selectedPolicyTypes[0].label as string
        )
      ),
    ],
    [stations, regionSets, selectedPolicyTypes]
  )

  useEffect(() => {
    const filters: LoadFilterType[] = []
    if (selectedStates.length) {
      filters.push(`state=${selectedStates.map((state) => state.id).join(",")}`)
    }
    if (selectedClients.length) {
      filters.push(
        `client_id=${selectedClients.map((client) => client.id).join(",")}`
      )
    }
    if (selectedRegionSetsOfPolicyType.length) {
      filters.push(
        `last_mile_region_set=${selectedRegionSetsOfPolicyType
          .map((regionSet) => regionSet.id)
          .join(",")}`
      )
    }
    if (selectedLastMileSiDos.length) {
      filters.push(
        `last_mile.si_do=${selectedLastMileSiDos
          .map((siDo) => siDo.id)
          .join(",")}`
      )
    }
    if (selectedShippingTypes.length) {
      filters.push(
        `shipping_type=${selectedShippingTypes
          .map((shippingType) => shippingType.id)
          .join(",")}`
      )
    }
    if (selectedDeliveryClasses.length) {
      filters.push(
        `delivery_class=${selectedDeliveryClasses
          .map((deliveryClass) => deliveryClass.id)
          .join(",")}`
      )
    }
    if (selectedOrganizations.length) {
      filters.push((load: Load) =>
        selectedOrganizations.some((v) => {
          return (
            load.forwardingInfo?.deliveryOrganizationId &&
            v.id === load.forwardingInfo?.deliveryOrganizationId
          )
        })
      )
    }
    if (selectedRound.length) {
      filters.push(`ready_time=${selectedRound[0].id}`)
    }
    if (
      (hasSelectedFromLM || hasSelectedReturning) &&
      selectedIsReady.length === 1
    ) {
      filters.push((load: Load) => {
        const isReady = !!load.readyTime || !!load.pickUpInfo
        return selectedIsReady[0].id === "true" ? isReady : !isReady
      })
    }
    setFilters(filters)
  }, [
    selectedStates,
    selectedClients,
    selectedRegionSetsOfPolicyType,
    selectedLastMileSiDos,
    selectedShippingTypes,
    selectedIsReady,
    selectedDeliveryClasses,
    selectedOrganizations,
    selectedRound,
    hasSelectedFromLM,
    hasSelectedReturning,
  ])
  useEffect(() => {
    if (!updatedFilters) return

    for (const [key, options] of Object.entries(updatedFilters)) {
      switch (key) {
        case "deliveryClasses":
          setSelectedDeliveryClasses(options)
          break
        case "isReady":
          setSelectedIsReady(options)
          break
        case "states":
          setSelectedStates(options)
          break
      }
    }
  }, [updatedFilters])
  return (
    <StatefulPopover
      content={({ close }) => (
        <div className="flex w-80 flex-col bg-white p-4">
          <FilterItem
            label="운송 서비스"
            options={DELIVERY_CLASSES.map((deliveryClass) => ({
              id: deliveryClass,
              label: getDeliveryClassName(deliveryClass),
            }))}
            value={selectedDeliveryClasses}
            setValue={setSelectedDeliveryClasses}
            canSelectAll
          />
          {hasSelectedReturning && (
            <FilterItem
              label="반품 준비 여부"
              options={[
                {
                  id: "true",
                  label: "준비됨",
                },
                {
                  id: "false",
                  label: "준비되지 않음",
                },
              ]}
              value={selectedIsReady}
              setValue={setSelectedIsReady}
            />
          )}
          <FilterItem
            label="운송 타입"
            options={[
              {
                id: "STATION_TO_LM",
                label: "출고지 → LM",
              },
              {
                id: "LM_TO_STATION",
                label: "LM → 출고지",
              },
              {
                id: "LM_TO_LM",
                label: "LM → LM",
              },
              {
                id: "STATION_TO_STATION",
                label: "출고지 → 출고지",
              },
            ]}
            value={selectedShippingTypes}
            setValue={setSelectedShippingTypes}
            canSelectAll
          />
          {!hasSelectedReturning && hasSelectedFromLM ? (
            <FilterItem
              label="인수 준비 여부"
              options={[
                {
                  id: "true",
                  label: "준비됨",
                },
                {
                  id: "false",
                  label: "준비되지 않음",
                },
              ]}
              value={selectedIsReady}
              setValue={setSelectedIsReady}
            />
          ) : null}
          <FilterItem
            label="배송 상태"
            options={LOAD_STATES.map((state) => ({
              id: state,
              label: getLoadStateName(state),
            }))}
            value={selectedStates}
            setValue={setSelectedStates}
            canSelectAll
          />
          {orgId ? null : (
            <FilterItem
              label="고객사"
              options={clientOptions}
              value={selectedClients}
              setValue={setSelectedClients}
              canSelectAll
            />
          )}
          <FilterItem
            label="정류소"
            options={stations?.map((station) => ({
              id: station.id,
              label: station.name,
              regionSets: regionSets?.filter((regionSet) =>
                regionSet.regionSetName.startsWith(station.name)
              ),
            }))}
            value={selectedStations}
            setValue={(value) => {
              setSelectedStations(value)
              const regionSetsToSelect = targetRegionSets.filter((regionSet) =>
                value.some(
                  (station) =>
                    regionSet.regionSetName === `${station.label as string}-*`
                )
              )
              setSelectedRegionSets(
                regionSetsToSelect.map((regionSet: RegionSet) => ({
                  id: `${selectedPolicyTypes[0].id}:${regionSet.regionSetName}`,
                  label: regionSet.regionSetName,
                }))
              )
              // XXX: 위탁 정류소의 경우 배송 조직으로 필터링 해야함
              if (value.some((v) => v.label === "CJ위탁")) {
                setSelectedOrganizations([
                  ...selectedOrganizations,
                  {
                    id: "6f72c4fe-d167-419b-92e1-da50420a4c3d",
                    label: "CJ대한통운",
                  },
                ])
              }
              if (value.some((v) => v.label === "한진위탁")) {
                setSelectedOrganizations([
                  ...selectedOrganizations,
                  {
                    id: "50849bc6-fa08-4b8c-8631-891b3a6f887c",
                    label: "한진",
                  },
                ])
              }
            }}
            canSelectAll
          />
          <FilterItem
            label="위탁 택배사"
            options={organizations
              ?.sort((a, b) =>
                a.name === "투데이"
                  ? -1
                  : b.name === "투데이"
                  ? 1
                  : a.name === "기타"
                  ? 1
                  : b.name === "기타"
                  ? -1
                  : a.name.localeCompare(b.name)
              )
              .map((org) => ({
                id: org.id,
                label: org.name,
              }))}
            value={selectedOrganizations}
            setValue={(value) => {
              setSelectedOrganizations(value)
            }}
            canSelectAll
          />
          <FilterItem
            label="LM 구역셋 운송 정책"
            options={policyTypes}
            value={selectedPolicyTypes}
            setValue={(value) => {
              setSelectedPolicyTypes(value)
            }}
            radio
            clearable={false}
          />
          <FilterItem
            label="LM 구역셋"
            options={targetRegionSets.map((regionSet) => ({
              id: `${selectedPolicyTypes[0].id}:${regionSet.regionSetName}`,
              label: regionSet.regionSetName,
            }))}
            value={selectedRegionSetsOfPolicyType}
            setValue={(value) => {
              setSelectedRegionSets(value)
              setSelectedStations([])
            }}
            canSelectAll
          />
          <FilterItem
            label="LM 시·도"
            options={["서울", "인천", "경기"]?.map((siDo) => ({
              id: siDo,
              label: siDo,
            }))}
            value={selectedLastMileSiDos}
            setValue={(value) => {
              setSelectedLastMileSiDos(value)
              setSelectedStations([])
            }}
            canSelectAll
          />
          <FilterItem
            label="SME 라운드"
            options={smeRounds.map((round) => ({
              id: round.id,
              label: round.name,
            }))}
            value={selectedRound}
            setValue={(value) => {
              setSelectedRound((prev) =>
                value.filter((v) => !prev.map((v) => v.id).includes(v.id))
              )
            }}
          />
          <Button
            className="mt-2"
            onClick={() => {
              onUpdateFilters(filters)
              close()
            }}
          >
            필터 적용
          </Button>
        </div>
      )}
    >
      <Button>{filters.length ? `필터 (${filters.length})` : "필터"}</Button>
    </StatefulPopover>
  )
}

export function getClientOptions(clients: Client[]) {
  clients.sort((a, b) => a.name.localeCompare(b.name))
  const TOP_CLIENT_NAMES = [
    "CJ대한통운",
    "한진",
    "롯데택배",
    "NS홈쇼핑",
    "홈앤쇼핑",
    "우진",
  ]
  const [testClients, realClients] = partition(clients, (client) =>
    client.name.includes("테스트")
  )
  const [largeClients, smallerClients] = partition(realClients, (client) =>
    TOP_CLIENT_NAMES.includes(client.name)
  )
  const [smeClients, regularClients] = partition(
    smallerClients,
    (client) => client.role === "SME"
  )
  const toOption = (client: Client) => ({
    id: client.clientId,
    label: client.name,
  })

  return {
    "주요 고객사":
      largeClients
        ?.sort(
          (a, b) =>
            TOP_CLIENT_NAMES.findIndex((name) => name === a.name) -
            TOP_CLIENT_NAMES.findIndex((name) => name === b.name)
        )
        .map(toOption) ?? [],
    "일반 고객사": regularClients?.map(toOption) ?? [],
    SME: smeClients?.map(toOption) ?? [],
    테스트: testClients?.map(toOption) ?? [],
  }
}

export function getStationsForFilter(stations: Station[]) {
  return stations
    .filter((s) => !["PICK_UP", "CLIENT_COLLECT"].includes(s.type))
    .sort((a, b) => {
      return a.name.localeCompare(b.name)
    })
}
