export type EndUserType = "B2B" | "B2C" | "C2B" | "C2C"

export const END_USER_TYPES: EndUserType[] = ["B2B", "B2C", "C2B", "C2C"]

export type ShippingType =
  | "STATION_TO_STATION"
  | "STATION_TO_LM"
  | "LM_TO_STATION"
  | "LM_TO_LM"

export const SHIPPING_TYPES: ShippingType[] = [
  "STATION_TO_STATION",
  "STATION_TO_LM",
  "LM_TO_STATION",
  "LM_TO_LM",
]

export type DeliveryClass =
  | "TO_19" // 저녁도착
  | "TO_24" // 오늘도착
  | "TO_48" // 내일도착
  | "FROM_72" // 빠른회수
  | "INCHEON_24" // 인천 오늘도착
  | "INCHEON_48" // 인천 내일도착
  | "FORWARDING_24" // 택배위탁
  | "TOGETHER_48" // 익일 투게더 (B2B)
  | "RETURNING" // 빠른반품

export const DELIVERY_CLASSES: DeliveryClass[] = [
  //"TO_19",
  "TO_24",
  "TO_48",
  //"FROM_72",
  "INCHEON_24",
  "INCHEON_48",
  //"TOGETHER_48",
  "FORWARDING_24",
  "RETURNING",
]

export const DELIVER_DELIVERY_CLASSES: DeliveryClass[] = [
  "TO_19",
  "TO_24",
  "TO_48",
  "INCHEON_24",
  "INCHEON_48",
  "TOGETHER_48",
]

export const PICK_UP_DELIVERY_CLASSES: DeliveryClass[] = [
  "FORWARDING_24",
  "RETURNING",
]

export type FailedAlimtalkResolveType = "DELIVERED" | "QUIT"
export const ALIMTALK_RESOLVE_TYPES: FailedAlimtalkResolveType[] = [
  "DELIVERED",
  "QUIT",
]

export type LoadSize = "소" | "중" | "대" | "특대" | "이형" | ""

export interface PhysicalAttributes {
  size: LoadSize
  width?: number
  depth?: number
  height?: number
  weight?: number
  fragile?: boolean
  fresh?: boolean
}
